<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <!-- <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert> -->

    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="12"
        md="12"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <logo />
                  <h3 class="text-primary invoice-logo">
                    Vuexy
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Office 290, 10330 152 Street
                </p>
                <p class="card-text mb-25">
                  Surrey, BC V3R 4G8
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    to="mailto: info@onikon.com"
                  >
                    info@onikon.com
                  </router-link>
                </p>
                <p>
                  <router-link
                    class="card-text mb-0"
                    to="tel:7785806107"
                  >
                    778.580.6107
                  </router-link>
                </p>
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">#5036</span>
                </h4>

                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Date Issued:
                  </p>
                  <p class="invoice-date">
                    19 Apr 2019
                  </p>
                </div>
                <b-badge
                  pill
                  class="mt-1 float-right"
                  :variant="getVarient('Paid')"
                >
                  Paid
                </b-badge>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice To:
                </h6>
                <h6 class="mb-25">
                  {{ client.name }}
                </h6>
                <p class="card-text mb-25">
                  {{ client.office }}
                </p>
                <p class="card-text mb-25">
                  {{ client.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ client.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Payment Details:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total Due:
                        </td>
                        <td><span class="font-weight-bold">$63</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Card Type:
                        </td>
                        <td><span class="font-weight-bold">{{ payment.type }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Credit Card:
                        </td>
                        <td><span class="font-weight-bold">**** **** **** {{ payment.card }}</span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Expiry Date:
                        </td>
                        <td><span class="font-weight-bold">{{ payment.expiry }}</span></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
          <b-table
            responsive
            :items="packageItems"
          />
          <div class="totalPriceContainer">
            <div class="invoice-total-wrapper ">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Subtotal:
                </p>
                <p class="invoice-total-amount">
                  ${{ price.subTotal }}
                </p>
              </div>
              <div class="invoice-total-item" v-if="price.discount">
                <p class="invoice-total-title">
                  Discount:
                </p>
                <p class="invoice-total-amount">
                  - ${{ price.discount }}
                </p>
              </div>
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Tax:
                </p>
                <p class="invoice-total-amount">
                  {{ price.tax * 100 }}%
                </p>
              </div>
              <hr class="my-50">
              <div class="invoice-total-item">
                <p class="invoice-total-title">
                  Total:
                </p>
                <p class="invoice-total-amount">
                  ${{ getTotalPrice }}
                </p>
              </div>
            </div>

          </div>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
    </b-row>

    <!-- <invoice-sidebar-send-invoice /> -->
    <!-- <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
// import { ref, onUnmounted } from '@vue/composition-api'
// import store from '@/store'
// import router from '@/router'
import {
  BRow, BCol, BCard, BCardBody, VBToggle, BBadge, BTable,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import Ripple from 'vue-ripple-directive'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTable,
    BBadge,
    Logo,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  data() {
    return {
      packageItems: [{
        item: 'Standard Package',
        price: '$85',
      }],
      client: {
        name: 'Jae Choi',
        office: 'ONIKON Creative',
        phone: '604-111-1111',
        email: 'jae.choi@onikon.com',
      },
      payment: {
        type: 'Visa',
        card: '1234',
        expiry: '06/21',
      },
      price: {
        subTotal: 85,
        discount: 25,
        tax: 0.05,
      },
    }
  },
  computed: {
    getTotalPrice() {
      return (this.price.subTotal - this.price.discount) + (this.price.subTotal - this.price.discount) * this.price.tax
    },
  },
  methods: {
    getVarient(status) {
      switch (status) {
        case 'Paid': return 'success'
        case 'Unpaid': return 'danger'
        default: return 'dark'
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
.totalPriceContainer{
    padding:2rem;
}
</style>
